<template>
  <div class="products">
    <div class="products__descr">
      <div class="products__title section-title">Лист ожидания</div>
      <div class="products__content section-description ">
        <p>
          В данном разделе собраны все товары, наличия которых ожидают пользователи вашего интернет-магазина.
          Увеличивайте продажи, возвращая в наличие наиболее ожидаемые товары.
        </p>

      </div>
    </div>
    <div class="products__list">
      <div v-for="product in productsList" :key="product.id" class="product">
        <div class="product__image">
          <img :src="product.first_image.url" />
        </div>
        <div class="product__content">
          <div class="product__title">{{ product.title }}</div>
          <div class="product__queue">Ожиданий наличия: <span>{{ productQueueCount(product.id) }}</span></div>
          <div class="product__variants">
            <div v-for="variant in product.variants" :key="variant.id" v-show="productVariantQueueCount(product.id, variant.id) > 0 && variant.title" class="product__variant">
              {{ variant.title }}: <span>{{ productVariantQueueCount(product.id, variant.id) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <paginate
        v-if="(subscriptionCount / 15) >= 2"
        :page-count="Math.floor(subscriptionCount / 15)"
        :click-handler="myCallback"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'">
    </paginate>
  </div>
</template>

<script>
export default {
  name: 'products',
  data() {
    return {
      subscriptionCount: 1,
      page: 1,
    }
  },
  methods: {
    myCallback(e) {
      this.page = e;
      this.getData();
    },
    getProducts() {
      if (this.accountData && this.accountData.insales_shop_url) {
        this.$store.dispatch('getProductsNewSubscriptions', {
          arrayId: this.productIdList.map(item => item.id),
          shopHostUrl: this.accountData.insales_shop_url
        })
      } else {
        setTimeout(() => {
          this.getProducts();
        }, 2000)
      }
    },
    getData() {
      this.$store.dispatch('getNewList', { page: this.page })
          .then((res) => {
            this.subscriptionCount = res.countResult;
            this.getProducts()
          })
    },

    productQueueCount(id) {
      return this.notConfirmSubscriptions.filter(sub => sub.product_id === id).length;
    },
    productVariantQueueCount(id, varId) {
      return this.notConfirmSubscriptions.filter(sub => sub.product_id === id && sub.product_mode_id === varId).length;
    }
  },
  computed: {
    accountData() {
      return this.$store.state.account.accountData;
    },
    productIdList() {
      const productIdArray = this.notConfirmSubscriptions
          .map(subscription => {
            return subscription.product_id;
          })
          .reduce((acc, item) => {
            if (acc.includes(item)) {
              return acc; // если значение уже есть, то просто возвращаем аккумулятор
            }
            return [...acc, item];
          }, []);
      const productIdList = productIdArray
        .map(id => {
          const list = this.notConfirmSubscriptions.filter(sub => sub.product_id === id)
          return {
            id,
            modeList: list
                .map(item => item.product_mode_id)
                .reduce((acc, item) => {
                  if (acc.includes(item)) {
                    return acc; // если значение уже есть, то просто возвращаем аккумулятор
                  }
                  return [...acc, item];
                }, [])
          }
        })
      return productIdList;
    },
    productsList() {
      return this.$store.state.products.productsNewSubscription || []
    },
    notConfirmSubscriptions() {
      return this.$store.state.subscriptions.subscriptionsNewList || []
    },
  },
  mounted() {
    if (! this.productsList.length) {
      this.getData();
    }
  }
}
</script>

<style lang="scss">
.products {
  padding: 30px;

  &__descr {
    margin-bottom: 30px;
    max-width: 600px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }
}
.product {
  width: 600px;
  border: 2px solid var(--color-border);
  background: #fff;
  border-radius: 30px;
  padding: 15px;
  display: flex;

  margin-bottom: 20px;
  margin-right: 20px;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 6px;
  }
  &__queue {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #747988;

    & span {
      font-weight: 700;
      color: var(--color-violet);
    }
  }
  &__variants {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  &__variant {
    margin-right: 14px;

    & span {
      font-weight: 700;
      color: var(--color-violet);
    }
  }

  &__image {
    width: 90px;
    min-width: 90px;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


</style>